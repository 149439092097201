<template>
  <el-row>
    <el-col class="cloud-doc" :xs="0" :sm="24" :md="24" :lg="24" :xl="24">
      <el-col class="module module-top">
        <el-col class="page-title">伊登云·跨平台</el-col>
        <el-col class="product">资产管理系统</el-col>
        <el-col class="trial-btn">
          <el-col class="btn" @click.native="tryOut">在线试用<img src="../../img/common/right_arrow_blue.svg"></el-col>
        </el-col>
      </el-col>
      <el-col class="module doc-information">
        <el-col class="doc-interface">
          <img src="@/img/product/assets/assets_interface.png">
        </el-col>
        <el-col class="doc-info">
          基于“RFID”的自动化资产管理系统可以帮助企业轻松管理企业内的固定资产库，对资产的整个生命周期实现全程信息化、智能化的管理，从而帮助企业全面掌握资产的分布以及运行状况，达到降低成本和提高运营效率的目的。
        </el-col>
        <el-col class="doc-fun">
          您的企业为是否存在以下资产管理难题
        </el-col>
        <el-col class="assets-problem">
          <el-col class="problem-list" :span="15">
            <el-col class="problem-item" :span="2">
              <el-col class="problem-icon">
                <img src="@/img/product/assets/icon/assets_icon_1.png">
              </el-col>
              <el-col class="problem-scheme">
                手工盘点
              </el-col>
            </el-col>
            <el-col class="problem-item" :span="2">
              <el-col class="problem-icon">
                <img src="@/img/product/assets/icon/assets_icon_2.png">
              </el-col>
              <el-col class="problem-scheme">
                信息滞后
              </el-col>
            </el-col>
            <el-col class="problem-item" :span="2">
              <el-col class="problem-icon">
                <img src="@/img/product/assets/icon/assets_icon_3.png">
              </el-col>
              <el-col class="problem-scheme">
                重复购置
              </el-col>
            </el-col>
            <el-col class="problem-item" :span="2">
              <el-col class="problem-icon">
                <img src="@/img/product/assets/icon/assets_icon_4.png">
              </el-col>
              <el-col class="problem-scheme">
                统一管理
              </el-col>
            </el-col>
            <el-col class="problem-item" :span="2">
              <el-col class="problem-icon">
                <img src="@/img/product/assets/icon/assets_icon_5.png">
              </el-col>
              <el-col class="problem-scheme">
                资产流失
              </el-col>
            </el-col>
            <el-col class="problem-item" :span="2">
              <el-col class="problem-icon">
                <img src="@/img/product/assets/icon/assets_icon_6.png">
              </el-col>
              <el-col class="problem-scheme">
                账目不符
              </el-col>
            </el-col>
          </el-col>
        </el-col>
        <el-col class="assets-solution">
          <el-col class="solution-title">
            伊登资产管理系统让你轻松实现
          </el-col>
          <el-col class="trial-btn">
            <el-col class="btn">在线试用<img src="../../img/common/right_arrow_white.svg"></el-col>
          </el-col>
          <el-col class="solution-list" :span="16">
            <el-col class="solution-item solution-find">
              自动发现
            </el-col>
            <el-col class="solution-item solution-monit">
              流转监控
            </el-col>
            <el-col class="solution-item solution-electric-fence">
              电子围栏
            </el-col>
            <el-col class="solution-item solution-inventory">
              一键盘点
            </el-col>
            <el-col class="solution-item solution-warning">
              异常预警
            </el-col>
          </el-col>
        </el-col>
        <el-col class="assets-goodness">
          <el-col class="scheme-title">
            方案优势
          </el-col>
          <el-row class="scheme-list" :span="16">
            <el-col style="height: 1px" :span="3"></el-col>
            <el-col class="scheme-item scheme-top" :span="6">
              <el-col class="scheme-img">
                <img src="@/img/product/assets/assets_scheme_1.png">
              </el-col>
              <el-col class="scheme-synopsis">
                实时数据获取
              </el-col>
              <el-col class="scheme-detail">
                自动生成资产报表，随时掌握集团所有资产的情况，避免人工耗时耗力盘点。
              </el-col>
            </el-col>
            <el-col class="scheme-item scheme-top" :span="6">
              <el-col class="scheme-img">
                <img src="@/img/product/assets/assets_scheme_2.png">
              </el-col>
              <el-col class="scheme-synopsis">
                资产安全可控
              </el-col>
              <el-col class="scheme-detail">
                让资产在授权区域活动，实现越区预警，实时掌握正要设备存放地点及轨迹。
              </el-col>
            </el-col>
            <el-col class="scheme-item scheme-top" :span="6">
              <el-col class="scheme-img">
                <img src="@/img/product/assets/assets_scheme_3.png">
              </el-col>
              <el-col class="scheme-synopsis">
                资产借用无忧
              </el-col>
              <el-col class="scheme-detail">
                资产外借使用无需派人现场跟踪，实现无人员跟进管理使用情况。
              </el-col>
            </el-col>
            <el-col style="height: 1px" :span="3"></el-col>
          </el-row>
          <el-row class="scheme-list" :span="16">
            <el-col style="height: 1px" :span="3"></el-col>
            <el-col class="scheme-item" :span="6">
              <el-col class="scheme-img">
                <img src="@/img/product/assets/assets_scheme_4.png">
              </el-col>
              <el-col class="scheme-synopsis">
                快速定位查找
              </el-col>
              <el-col class="scheme-detail">
                定位跟踪资产位置信息，可以省去人工查找的烦恼。
              </el-col>
            </el-col>
            <el-col class="scheme-item" :span="6">
              <el-col class="scheme-img">
                <img src="@/img/product/assets/assets_scheme_5.png">
              </el-col>
              <el-col class="scheme-synopsis">
                资产状态监控
              </el-col>
              <el-col class="scheme-detail">
                监管设备的运行状态，提高设备的使用率清楚资产的折损情况，避免重复采购。
              </el-col>
            </el-col>
            <el-col class="scheme-item" :span="6">
              <el-col class="scheme-img">
                <img src="@/img/product/assets/assets_scheme_6.png">
              </el-col>
              <el-col class="scheme-synopsis">
                预防资产过期
              </el-col>
              <el-col class="scheme-detail">
                对于需要保养的资产，实时提醒对资产进行保养。
              </el-col>
            </el-col>
            <el-col style="height: 1px" :span="3"></el-col>
          </el-row>

        </el-col>
      </el-col>
      <el-col class="module system-architecture">
        <el-col class="title">
          系统架构
        </el-col>
        <el-col class="img">
          <img src="@/img/product/assets/system_architecture.png">
        </el-col>
        <el-col class="detail">
          伊登固定资产管理解決方案思路以账实相符、责任到人、准确高效为出发点，结合RFID硬件为企业打造的固定资产全生命周期管理方案。
        </el-col>
      </el-col>
    </el-col>

    <el-col class="cloud-doc" :xs="24" :sm="0" :md="0" :lg="0" :xl="0">
      <el-col class="module module-top">
        <el-col class="page-title">伊登云·跨平台</el-col>
        <el-col class="product">资产管理系统</el-col>
        <el-col class="trial-btn">
          <el-col class="btn" @click.native="tryOut">在线试用<img src="../../img/common/right_arrow_blue.svg"></el-col>
        </el-col>
      </el-col>
      <el-col class="module doc-information">
        <el-row>
        <el-col class="doc-interface">
          <img src="@/img/product/assets/assets_interface.png">
        </el-col>
        <el-col class="doc-info">
          基于“RFID”的自动化资产管理系统可以帮助企业轻松管理企业内的固定资产库，对资产的整个生命周期实现全程信息化、智能化的管理，从而帮助企业全面掌握资产的分布以及运行状况，达到降低成本和提高运营效率的目的。
        </el-col>
        <el-col class="doc-fun">
          您的企业为是否存在以下资产管理难题
        </el-col>
        </el-row>
        <el-row class="assets-problem">
          <el-col class="problem-list" :span="24">
            <el-row>
              <el-col style="height: 1px" :span="1"></el-col>
              <el-col class="problem-item" :span="6">
                <el-col class="problem-icon">
                  <img src="@/img/product/assets/icon/assets_icon_1.png">
                </el-col>
                <el-col class="problem-scheme">
                  手工盘点
                </el-col>
              </el-col>
              <el-col class="problem-item" :span="6">
                <el-col class="problem-icon">
                  <img src="@/img/product/assets/icon/assets_icon_2.png">
                </el-col>
                <el-col class="problem-scheme">
                  信息滞后
                </el-col>
              </el-col>
              <el-col class="problem-item" :span="6">
                <el-col class="problem-icon">
                  <img src="@/img/product/assets/icon/assets_icon_3.png">
                </el-col>
                <el-col class="problem-scheme">
                  重复购置
                </el-col>
              </el-col>
              <el-col style="height: 1px" :span="1"></el-col>
            </el-row>
            <el-row style="margin-top: 10px">
              <el-col style="height: 1px" :span="1"></el-col>
              <el-col class="problem-item" :span="6">
                <el-col class="problem-icon">
                  <img src="@/img/product/assets/icon/assets_icon_4.png">
                </el-col>
                <el-col class="problem-scheme">
                  统一管理
                </el-col>
              </el-col>
              <el-col class="problem-item" :span="6">
                <el-col class="problem-icon">
                  <img src="@/img/product/assets/icon/assets_icon_5.png">
                </el-col>
                <el-col class="problem-scheme">
                  资产流失
                </el-col>
              </el-col>
              <el-col class="problem-item" :span="6">
                <el-col class="problem-icon">
                  <img src="@/img/product/assets/icon/assets_icon_6.png">
                </el-col>
                <el-col class="problem-scheme">
                  账目不符
                </el-col>
              </el-col>
              <el-col style="height: 1px" :span="1"></el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-col class="assets-solution">
          <el-col class="solution-title">
            伊登资产管理系统让你轻松实现
          </el-col>
          <el-col class="trial-btn">
            <el-col class="btn">在线试用<img src="../../img/common/right_arrow_white.svg"></el-col>
          </el-col>
          <el-col class="solution-list" :span="24">
            <el-col style="height: 1px" :span="1"></el-col>
            <el-col class="solution-item solution-find" :span="5">
              <img src="../../img/product/assets/assets_solution_1.png">
              <div>自动发现</div>
            </el-col>
            <el-col class="solution-item solution-monit" :span="5">
              <img src="../../img/product/assets/assets_solution_2.png">
              <div>流转监控</div>
            </el-col>
            <el-col class="solution-item solution-electric-fence" :span="5">
              <img src="../../img/product/assets/assets_solution_3.png">
              <div>电子围栏</div>
            </el-col>
            <el-col class="solution-item solution-inventory" :span="5">
              <img src="../../img/product/assets/assets_solution_4.png">
              <div>一键盘点</div>
            </el-col>
            <el-col class="solution-item solution-warning" :span="5">
              <img src="../../img/product/assets/assets_solution_5.png">
              <div>异常预警</div>
            </el-col>
            <el-col style="height: 1px" :span="0"></el-col>
          </el-col>
        </el-col>
        <el-col class="assets-goodness">
          <el-col class="scheme-title">
            方案优势
          </el-col>
          <el-row class="scheme-list" :span="16">
            <el-col style="height: 1px" :span="1"></el-col>
            <el-col class="scheme-item scheme-top" :span="11">
              <el-col class="scheme-img">
                <img src="@/img/product/assets/assets_scheme_1.png">
              </el-col>
              <el-col class="scheme-synopsis">
                实时数据获取
              </el-col>
              <el-col class="scheme-detail">
                自动生成资产报表，随时掌握集团所有资产的情况，避免人工耗时耗力盘点。
              </el-col>
            </el-col>
            <el-col class="scheme-item scheme-top" :span="11">
              <el-col class="scheme-img">
                <img src="@/img/product/assets/assets_scheme_2.png">
              </el-col>
              <el-col class="scheme-synopsis">
                资产安全可控
              </el-col>
              <el-col class="scheme-detail">
                让资产在授权区域活动，实现越区预警，实时掌握正要设备存放地点及轨迹。
              </el-col>
            </el-col>

            <el-col style="height: 1px" :span="1"></el-col>
          </el-row>

          <el-row class="scheme-list" :span="16">
            <el-col style="height: 1px" :span="1"></el-col>
            <el-col class="scheme-item scheme-top" :span="11">
              <el-col class="scheme-img">
                <img src="@/img/product/assets/assets_scheme_3.png">
              </el-col>
              <el-col class="scheme-synopsis">
                资产借用无忧
              </el-col>
              <el-col class="scheme-detail">
                资产外借使用无需派人现场跟踪，实现无人员跟进管理使用情况。
              </el-col>
            </el-col>
            <el-col class="scheme-item" :span="11">
              <el-col class="scheme-img">
                <img src="@/img/product/assets/assets_scheme_4.png">
              </el-col>
              <el-col class="scheme-synopsis">
                快速定位查找
              </el-col>
              <el-col class="scheme-detail">
                定位跟踪资产位置信息，可以省去人工查找的烦恼。
              </el-col>
            </el-col>
            <el-col style="height: 1px" :span="1"></el-col>
          </el-row>


          <el-row class="scheme-list" :span="16">
            <el-col style="height: 1px" :span="1"></el-col>

            <el-col class="scheme-item" :span="11">
              <el-col class="scheme-img">
                <img src="@/img/product/assets/assets_scheme_5.png">
              </el-col>
              <el-col class="scheme-synopsis">
                资产状态监控
              </el-col>
              <el-col class="scheme-detail">
                监管设备的运行状态，提高设备的使用率清楚资产的折损情况，避免重复采购。
              </el-col>
            </el-col>
            <el-col class="scheme-item" :span="11">
              <el-col class="scheme-img">
                <img src="@/img/product/assets/assets_scheme_6.png">
              </el-col>
              <el-col class="scheme-synopsis">
                预防资产过期
              </el-col>
              <el-col class="scheme-detail">
                对于需要保养的资产，实时提醒对资产进行保养。
              </el-col>
            </el-col>
            <el-col style="height: 1px" :span="1"></el-col>
          </el-row>

        </el-col>
      </el-col>
      <el-col class="module system-architecture">
        <el-col class="title">
          系统架构
        </el-col>
        <el-col class="img">
          <img src="@/img/product/assets/system_architecture.png">
        </el-col>
        <el-col class="detail">
          伊登固定资产管理解決方案思路以账实相符、责任到人、准确高效为出发点，结合RFID硬件为企业打造的固定资产全生命周期管理方案。
        </el-col>
      </el-col>
    </el-col>

    <el-col class="trial-back" style="position: relative;">
      <img src="../../img/product/cloudDoc/bg_2.png" style="height: 100%;width: 100%">
      <el-col class="btn btn-position" @click.native="tryNow" style="">在线试用</el-col>
    </el-col>
  </el-row>
</template>

<script>
import CloudDoc from "./cloudDoc";
export default {
  name: "assets",
    data() {
      return {

      }
    },
    methods: {
        tryOut() {
            this.$alert('正在开发中，敬请期待');
        }
    },
  components: {CloudDoc}
}
</script>

<style lang="less" scoped>

@media screen and (max-width: 575px) {
  .cloud-doc {
    background: #FFFFFF;
    margin-bottom: 10vh;

    .module-top {
      margin-top: 96px;

      .page-title {
        text-align: center;
        font-size: 48px;
        font-weight: 600;
        color: #3764D7;
        line-height: 67px;
      }

      .product {
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        color: #3764D7;
        line-height: 42px;
        margin-top: 24px;
      }


    }

    .doc-information {
      background: url("../../img/product/cloudDoc/Group 1475.png") no-repeat top center;
      width: 100%;
      margin-top: 159px;

      .doc-interface {
        float: unset;
        margin: auto;
        position: relative;
        bottom: 111px;
        img{
          height: 100%;
          width: 100%;
        }
      }

      .doc-info {
        height: 48px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        float: unset;
        margin: auto;
        text-align: center;
        position: relative;
        bottom: 85px;
      }

      .doc-fun {
        font-size: 1.2rem;
        font-weight: 600;
        color: #FFFFFF;
        /*line-height: 48px;*/
        text-align: center;
      }

      .assets-problem {
        margin-top: 25px;

        .problem-list {
          margin: auto;
          float: unset;

          .problem-item:last-child {
            margin-right: 0;
          }

          .problem-item {
            text-align: center;
            margin-right: 6vw;

            .problem-scheme {
              font-size: 20px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 28px;
            }
          }
        }
      }

      .assets-solution {
        margin-top: 10vh;

        .solution-title {
          font-size: 1.5rem;
          font-weight: 600;
          color: #3764D7;
          line-height: 48px;
          text-align: center;
        }

        .trial-btn {
          .btn {
            background: #3764D7;
            color: #FFFFFF;
            border: 1px solid #FFFFFF;
          }
        }

        .solution-list {
          margin: auto;
          float: unset;

          .solution-item:last-child {
            margin-right: 0;
          }

          .solution-item {
            font-size: 24px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 33px;
            text-align: center;
            padding-top: 164px;
            width: 18vw;
            margin-right: 1vw;
            margin-top: 48px;
          }

          .solution-find {
            position: relative;
            img{
              height: 100%;
              width: 100%;
              position: absolute;
              top:0px;
              left: 0px;
            }

            div{
              position: absolute;
              top: 30%;
              left: 0px;
            }
          }

          .solution-monit {
            position: relative;
            img{
              height: 100%;
              width: 100%;
              position: absolute;
              top:0px;
              left: 0px;
            }

            div{
              position: absolute;
              top: 30%;
              left: 0px;
            }
          }

          .solution-electric-fence {
            position: relative;
            img{
              height: 100%;
              width: 100%;
              position: absolute;
              top:0px;
              left: 0px;
            }

            div{
              position: absolute;
              top: 30%;
              left: 0px;
            }
          }

          .solution-inventory {
            position: relative;
            img{
              height: 100%;
              width: 100%;
              position: absolute;
              top:0px;
              left: 0px;
            }

            div{
              position: absolute;
              top: 30%;
              left: 0px;
            }
          }

          .solution-warning {
            position: relative;
            img{
              height: 100%;
              width: 100%;
              position: absolute;
              top:0px;
              left: 0px;
            }

            div{
              position: absolute;
              top: 30%;
              left: 0px;
            }
          }
        }
      }

      .assets-goodness {
        margin-top: 10vh;

        .scheme-title {
          font-size: 40px;
          font-weight: 600;
          color: #3764D7;
          line-height: 48px;
          text-align: center;
          margin-bottom: 48px;
        }

        .scheme-list {
          margin: auto;
          float: unset;
          text-align: justify;

          .scheme-top {
            margin-bottom: 64px;
          }

          .scheme-item {
            text-align: center;

            .scheme-synopsis {
              font-size: 24px;
              font-weight: 500;
              color: #3764D7;
              line-height: 32px;
            }

            .scheme-detail {
              font-size: 16px;
              font-weight: 400;
              color: #414141;
              line-height: 24px;
              margin-top: 24px;
            }

            .scheme-img {
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }

    }

    .system-architecture {
      margin-top: 10vh;

      .title {
        font-size: 40px;
        font-weight: 500;
        color: #3764D7;
        line-height: 48px;
        text-align: center;
      }

      .img {
        text-align: center;
        margin-top: 43px;

        img {
          height: 100%;
          width: 90%;
        }
      }

      .detail {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #414141;
        line-height: 25px;
        margin-top: 23px;
      }
    }

  }

  .trial-back {
    text-align: center;
    margin-top: 32px;

    .btn {
      border: 1px solid #3764D7;
      border-radius: 22px;
      width: 192px;
      height: 44px;
      font-size: 20px;
      font-weight: 500;
      color: #3764D7;
      line-height: 28px;
      float: unset;
      margin: auto;
      padding-top: 6px;
      background-color: #FFFFFF;
    }

    .btn-position {
      position: absolute;
      left: 25%;
      top: 50%;
    }

    .btn:hover {
      cursor: pointer;
    }
  }

  .trial-btn {
    text-align: center;
    margin-top: 32px;

    .btn {
      border: 1px solid #3764D7;
      border-radius: 22px;
      width: 192px;
      height: 44px;
      font-size: 20px;
      font-weight: 500;
      color: #3764D7;
      line-height: 28px;
      float: unset;
      margin: auto;
      padding-top: 6px;
      background-color: #FFFFFF;
    }

    .btn:hover {
      cursor: pointer;
    }

    img {
      margin-left: 7px;
      padding-bottom: 3px;
    }

  }

  .trial-back {
    /*background: url("../../img/product/cloudDoc/bg_2.png") no-repeat top center;*/
    width: 100%;
    height: 224px;

    .trial-btn {
      margin-top: 80px;

      .btn {
        width: 290px;
        height: 64px;
        padding-top: 16px;
        border-radius: 28px;
        font-size: 24px;
        color: #2974E4;
        line-height: 32px;
        font-weight: 400;
      }
    }
  }
}

@media screen and (min-width: 576px) {
  .cloud-doc {
    background: #FFFFFF;
    margin-bottom: 120px;

    .module-top {
      margin-top: 96px;

      .page-title {
        text-align: center;
        font-size: 48px;
        font-weight: 600;
        color: #3764D7;
        line-height: 67px;
      }

      .product {
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        color: #3764D7;
        line-height: 42px;
        margin-top: 24px;
      }


    }

    .doc-information {
      background: url("../../img/product/cloudDoc/bg_1.png") no-repeat top center;
      width: 100%;
      margin-top: 159px;

      .doc-interface {
        float: unset;
        margin: auto;
        width: 732px;
        position: relative;
        bottom: 111px;
      }

      .doc-info {
        width: 542px;
        height: 48px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        float: unset;
        margin: auto;
        text-align: center;
        position: relative;
        bottom: 85px;
      }

      .doc-fun {
        font-size: 40px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 48px;
        text-align: center;
      }

      .assets-problem {
        margin-top: 48px;

        .problem-list {
          margin: auto;
          float: unset;

          .problem-item:last-child {
            margin-right: 0;
          }

          .problem-item {
            text-align: center;
            margin-right: 6vw;

            .problem-scheme {
              font-size: 20px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 28px;
            }
          }
        }
      }

      .assets-solution {
        margin-top: 120px;

        .solution-title {
          font-size: 40px;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 48px;
          text-align: center;
        }

        .trial-btn {
          .btn {
            background: #3764D7;
            color: #FFFFFF;
            border: 1px solid #FFFFFF;
          }
        }

        .solution-list {
          margin: auto;
          float: unset;

          .solution-item:last-child {
            margin-right: 0;
          }

          .solution-item {
            font-size: 24px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 33px;
            text-align: center;
            padding-top: 164px;
            width: 11vw;
            height: 361px;
            margin-right: 2vw;
            margin-top: 48px;
          }

          .solution-find {
            background: url("../../img/product/assets/assets_solution_1.png") no-repeat top center;
          }

          .solution-monit {
            background: url("../../img/product/assets/assets_solution_2.png") no-repeat top center;
          }

          .solution-electric-fence {
            background: url("../../img/product/assets/assets_solution_3.png") no-repeat top center;
          }

          .solution-inventory {
            background: url("../../img/product/assets/assets_solution_4.png") no-repeat top center;
          }

          .solution-warning {
            background: url("../../img/product/assets/assets_solution_5.png") no-repeat top center;
          }
        }
      }

      .assets-goodness {
        margin-top: 120px;

        .scheme-title {
          font-size: 40px;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 48px;
          text-align: center;
          margin-bottom: 48px;
        }

        .scheme-list {
          margin: auto;
          float: unset;
          text-align: justify;

          .scheme-top {
            margin-bottom: 64px;
          }

          .scheme-item {
            text-align: center;

            .scheme-synopsis {
              font-size: 24px;
              font-weight: 500;
              color: #3764D7;
              line-height: 32px;
              margin-top: 47px;
            }

            .scheme-detail {
              font-size: 16px;
              font-weight: 400;
              color: #414141;
              line-height: 24px;
              margin-top: 24px;
              padding: 0 40px;
            }

            .scheme-img {
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }

    }

    .system-architecture {
      margin-top: 120px;

      .title {
        font-size: 40px;
        font-weight: 500;
        color: #3764D7;
        line-height: 48px;
        text-align: center;
      }

      .img {
        text-align: center;
        margin-top: 43px;

        img {
          height: 100%;
          width: 60%;
        }
      }

      .detail {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #414141;
        line-height: 49px;
        margin-top: 23px;
      }
    }

  }

  .trial-back {
    text-align: center;
    margin-top: 32px;

    .btn {
      border: 1px solid #3764D7;
      border-radius: 22px;
      width: 192px;
      height: 44px;
      font-size: 20px;
      font-weight: 500;
      color: #3764D7;
      line-height: 28px;
      float: unset;
      margin: auto;
      padding-top: 6px;
      background-color: #FFFFFF;
    }

    .btn-position {
      position: absolute;
      left: 44%;
      top: 50%;
    }

    .btn:hover {
      cursor: pointer;
    }
  }

  .trial-btn {
    text-align: center;
    margin-top: 32px;

    .btn {
      border: 1px solid #3764D7;
      border-radius: 22px;
      width: 192px;
      height: 44px;
      font-size: 20px;
      font-weight: 500;
      color: #3764D7;
      line-height: 28px;
      float: unset;
      margin: auto;
      padding-top: 6px;
      background-color: #FFFFFF;
    }

    .btn:hover {
      cursor: pointer;
    }

    img {
      margin-left: 7px;
      padding-bottom: 3px;
    }

  }

  .trial-back {
    /*background: url("../../img/product/cloudDoc/bg_2.png") no-repeat top center;*/
    width: 100%;
    height: 224px;

    .trial-btn {
      margin-top: 80px;

      .btn {
        width: 290px;
        height: 64px;
        padding-top: 16px;
        border-radius: 28px;
        font-size: 24px;
        color: #2974E4;
        line-height: 32px;
        font-weight: 400;
      }
    }
  }
}

</style>
